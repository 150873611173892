import Vue from "vue";
import Vuex from "vuex";
import { $api, setProp, deleteProp, arrToObj } from "bh-mod";

Vue.use(Vuex);

export default {
	state: {
		priceManagement: "lot",
		homeDetails: {
			visible: false,
			units: [],
			unit: "",
			lot: "",
		},
		availableUnits: {},
		displayView: "map",
		priceBumpDialog: {
			visible: false,
			type: "",
			ids: [],
			cb: () => { },
		},
		timeline: {},
		priceBump: {
			visible: false,
			type: "",
			name: "",
			ids: [],
			description: "",
			percentage: false,
			amount: 0,
			cb: () => { },
		},
		highrisePriceBump: {
			visible: false,
			type: "",
			ids: [],
			percentage: false,
			amount: 0,
			cb: () => { },
		},
		condoPremiums: {},
		condoAddons: { parking: [], lockers: [], bikeRacks: [] },
		condoSettings: {},
		premiums: {},
		gradings: {},
		newTime: 1231321,
		allSettings: {},
		lots: {},
		units: {},
		unitGroups: {},
		filterDrawer: {
			visible: false,
			filter: null,
		},
		tags: {},
		lowriseFilter: {
			assignee: ["any"],
			price: [0, 0],
			frontage: [0],
			unitGroup: "any",
			elevation: "any",
			modelType: ["any"],
			tags: ["any"],
			street: "any",
			premiums: ["any"],
		},
		highriseFilter: {
			assignee: ["any"],
			type: ["any"],
			price: [0, 0],
			sqft: [0, 0],
			beds: ["any"],
			baths: "any",
			floor: "any",
			exposure: ["any"],
			exterior: ["any"],
			tags: ["any"],
		},
		addOnsFilter: {
			assignee: ["any"],
			parkingType: ["any"],
			lockersType: ["any"],
			parkingPrice: [0, 0],
			lockersPrice: [0, 0],
			bikeRacksPrice: [0, 0],
			tags: ["any"],
		},
		lotFilter: {
			price: [0, 0],
			frontage: [0],
			modelType: ["any"],
			tags: ["any"],
			street: "any",
			premiums: ["any"],
			status: ["any"],
		},
		elevationFilter: {
			price: [0, 0],
			frontage: [0],
			modelType: ["any"],
			beds: ["any"],
			baths: ["any"],
			unitGroup: "any",
			elevation: "any",
			tags: ["any"],
		},
		lowriseArray: {},
		selectedFloor: "",
		crumbs: "inventory",
		currentTab: "",
		lotArray: {},
		elevationArray: {},
		highriseArray: {},
		catUnits: {},
		siteplan: {},
		siteplanMap: {},
		detailModal: {
			visible: false,
			units: [],
			lot: {},
		},
		unitDetails: {
			visible: false,
			unit: "",
		},
		updateStatus: {
			visible: false,
		},
		users: [],
		currentView: "grid"
	},
	mutations: {
		UPDATE_INVENTORY_VIEW(state, data) {
			state.currentView = data;
		},
		UPDATE_PRICE_MANAGEMENT(state, data) {
			state.priceManagement = data;
		},
		OPEN_HOME_DETAILS(state, data) {
			state.homeDetails.visible = true;
			state.homeDetails.units = data.units;
			state.homeDetails.unit = data.unit;
			state.homeDetails.lot = data.lot;
		},
		CLOSE_HOME_DETAILS(state) {
			state.homeDetails.visible = false;
			state.homeDetails.units = [];
			state.homeDetails.unit = "";
			state.homeDetails.lot = "";
		},
		CLOSE_UPDATE_STATUS(state) {
			state.updateStatus.visible = false;
		},
		OPEN_UPDATE_STATUS(state) {
			state.updateStatus.visible = true;
		},
		OPEN_DETAILS(state, data) {
			state.detailModal.visible = true;
			state.detailModal.lot = data.lot;
			state.detailModal.units = data.units;
		},
		OPEN_UNIT_DETAILS(state, data) {
			state.unitDetails.visible = true;
			state.unitDetails.unit = data;
		},
		CLOSE_UNIT_DETAILS(state) {
			state.unitDetails.visible = false;
			state.unitDetails.unit = "";
		},
		CLOSE_DETAILS(state) {
			state.detailModal.visible = false;
			state.detailModal.lot = {};
			state.detailModal.units = [];
		},
		CHANGE_VIEW(state, data) {
			state.displayView = data;
		},
		DO_BUMP(state, data) {
			let {
				type,
				ids,
				premiums,
				gradings,
				packages,
				priceBump = [],
				percentage,
				amount,
				instance = {
					productType: "lowrise",
				},
			} = data;

			let tmpPriceBump = {};
			priceBump.forEach((price) => {
				tmpPriceBump[price.id] = price;
			});
			priceBump = tmpPriceBump;

			if (type === "lots") {
				ids
					.filter((id) => state.lots[id])
					.forEach((id) => {
						let lot = state.lots[id];
						premiums.forEach((premium) => {
							if (
								lot.other.premiums &&
								lot.other.premiums[premium.id] &&
								lot.other.premiums[premium.id].active
							) {
								let newPrice = lot.other.premiums[premium.id].price;
								if (premium.percentage) {
									newPrice =
										lot.other.premiums[premium.id].price *
										(1 + premium.amount / 100);
								} else {
									newPrice =
										lot.other.premiums[premium.id].price + premium.amount;
								}

								newPrice = Math.max(0, newPrice);

								setProp(
									state,
									["lots", id, "other", "premiums", premium.id, "price"],
									newPrice
								);
							}
						});
						gradings.forEach((grading) => {
							if (
								lot.other.gradings &&
								lot.other.gradings[grading.id] &&
								lot.other.gradings[grading.id].active
							) {
								let newPrice = lot.other.gradings[grading.id].price;
								if (grading.percentage) {
									newPrice =
										lot.other.gradings[grading.id].price *
										(1 + grading.amount / 100);
								} else {
									newPrice =
										lot.other.gradings[grading.id].price + grading.amount;
								}
								newPrice = Math.max(0, newPrice);
								setProp(
									state,
									["lots", id, "other", "gradings", grading.id, "price"],
									newPrice
								);
							}
						});
					});
			} else if (type === "units" && instance.productType === "lowrise") {
				ids
					.filter((id) => state.units[id])
					.forEach((id) => {
						let unit = state.units[id];
						let packs = {};
						packages.forEach((pack) => {
							packs[pack.id] = pack;
						});
						unit.packages.forEach((pkg, pkgI) => {
							if (packages.map((x) => x.id).includes(pkg.id)) {
								let newPrice = pkg.price;
								if (packs[pkg.id].percentage) {
									newPrice = pkg.price * (1 + packs[pkg.id].amount / 100);
								} else {
									newPrice = pkg.price + packs[pkg.id].amount;
								}
								newPrice = Math.max(0, newPrice);
								setProp(
									state,
									["units", id, "packages", pkgI, "price"],
									newPrice
								);
							}
						});
					});
			} else if (type === "units" && instance.productType === "highrise") {
				ids
					.filter((id) => state.units[id])
					.forEach((id) => {
						let unit = state.units[id];

						if (priceBump[unit.id]) {
							amount = priceBump[unit.id].amount;
							percentage = Boolean(priceBump[unit.id].percentage);
						}
						if (percentage)
							unit.packages[0].price =
								unit.packages[0].price * (1 + amount / 100);
						else unit.packages[0].price += amount;
						state.units[id] = unit;
					});
			} else if (type === "premium") {
				ids
					.filter((id) => state.condoPremiums[id])
					.forEach((id) => {
						let premium = state.condoPremiums[id];

						if (priceBump[premium.id]) {
							amount = priceBump[premium.id].amount;
							percentage = Boolean(priceBump[premium.id].percentage);
						}
						if (percentage) premium.value = premium.value * (1 + amount / 100);
						else premium.value += amount;
						state.condoPremiums[id] = premium;
					});
			} else if (["parking", "lockers", "bikeracks"].includes(type)) {
				if (type === "bikeracks") {
					type = "bikeRacks";
				}
				state.condoAddons[type] = state.condoAddons[type].map((addOn) => {
					if (priceBump[addOn.id]) {
						amount = priceBump[addOn.id].amount;
						percentage = Boolean(priceBump[addOn.id].percentage);
					}

					if (ids.includes(addOn.id)) {
						if (percentage) addOn.price = addOn.price * (1 + amount / 100);
						else addOn.price += amount;
					}
					return addOn;
				});
			}
			state.newTime = Date.now();
		},
		OPEN_BUMP(state, payload) {
			let { type, ids, cb } = payload;
			state.priceBump = {
				visible: true,
				type,
				ids,
				cb,
			};
		},
		OPEN_HIGHRISE_BUMP(state, payload) {
			let { type, ids, cb } = payload;
			state.highrisePriceBump = {
				visible: true,
				type,
				ids,
				cb,
			};
		},
		UPDATE_AVAIL_UNITS(state, data) {
			setProp(state, ["availableUnits", data.id], data.units);
		},
		ADD_TIMELINE(state, payload) {
			setProp(state, ["timeline", payload.id], payload);
			state.newTime = Date.now();
		},
		SET_TIMELINES(state, payload) {
			state.timeline = arrToObj(payload);
			state.newTime = Date.now();
		},
		OPEN_BUMP_HISTORY(state, payload) {
			let { type, ids, cb = () => { } } = payload;
			if (!ids.length) return;
			state.priceBumpDialog = {
				visible: true,
				type,
				ids,
				cb,
			};
		},
		CLOSE_BUMP_HISTORY(state) {
			state.priceBumpDialog.visible = false;
		},
		CLOSE_BUMP(state) {
			state.priceBump = {
				visible: false,
				type: "",
				name: "",
				ids: [],
				cb: () => { },
				description: "",
				percentage: false,
				amount: 0,
			};
		},
		CLOSE_HIGHRISE_BUMP(state) {
			state.highrisePriceBump = {
				visible: false,
				type: "",
				ids: [],
				cb: () => { },
				percentage: false,
				amount: 0,
			};
		},
		DO_TAGS(state, data) {
			let tags = data;
			tags.forEach((tag) => {
				setProp(state.tags, [tag.id], tag);
			});
		},
		ADD_TAG(state, data) {
			let tag = data;
			setProp(state.tags, [tag.id], tag);
		},
		REMOVE_TAGS(state) {
			state.tags = {};
		},
		UPDATE_CRUMBS(state, data) {
			state.crumbs = data;
		},
		CHANGE_TAB(state, data) {
			state.priceManagement = data;
		},
		UPDATE_LOTS(state, data) {
			if (data.attachedUnit && data.attachedUnit.id)
				data.attachedUnit = data.attachedUnit.id;
			if (data.tags && data.tags.length) {
				data.tags = data.tags.map((tag) => {
					if (typeof tag == "object") return tag.id;
					else return tag;
				});
			}
			setProp(state.lots, [data.id], data);
			state.newTime = new Date().getTime();
		},
		UPDATE_UNITS(state, data) {
			if (data.tags && data.tags.length) {
				data.tags = data.tags.map((tag) => {
					if (typeof tag == "object") return tag.id;
					else return tag;
				});
			}
			setProp(state.units, [data.id], data);
			state.newTime = new Date().getTime();
		},

		UPDATE_ELEVATION_FILTER(state, data) {
			return (state.elevationFilter = data);
		},
		UPDATE_HIGHRISE_FILTER(state, data) {
			state.highriseFilter = data;
		},
		UPDATE_ADDONS_FILTER(state, data) {
			state.addOnsFilter = data;
		},
		UPDATE_LOT_FILTER(state, data) {
			return (state.lotFilter = data);
		},
		UPDATE_LOWRISE_FILTER(state, data) {
			return (state.lowriseFilter = data);
		},
		OPEN_FILTER(state, data = null) {
			state.filterDrawer.visible = true;
			state.filterDrawer.filter = data;
		},
		CLOSE_FILTER(state) {
			state.filterDrawer.visible = false;
			state.filterDrawer.filter = null;
		},
		RESET_DEFAULT_FILTER(state) {
			state.filterDrawer.filter = null;
		},
		SET_SETTINGS(state, data) {
			state.allSettings = data;
			if (!state.allSettings.app) {
				Vue.set(state.allSettings, 'app', {})
			}
			if (!state.allSettings.app.options) {
				Vue.set(state.allSettings.app, 'options', {})
			}
			if (!state.allSettings.app.options.commissionPayoutStructure) {
				Vue.set(state.allSettings.app.options, 'commissionPayoutStructure', [])
			}
			if (!state.allSettings.app.options.agentCommissionStructure) {
				Vue.set(state.allSettings.app.options, 'agentCommissionStructure', {
					commissionType: "percentage",
					commissionAmount: null,
					lotCommissionType: "percentage",
					lotCommissionAmount: null,
					lotCommissionIsActive: false,
					unitCommissionType: "percentage",
					unitCommissionAmount: null,
					unitCommissionIsActive: true,
					addOnCommissionType: "percentage",
					addOnCommissionAmount: null,
					addOnCommissionIsActive: true
				})
			}
			if (!state.allSettings.app.options.reservationRefundFees) {
				Vue.set(state.allSettings.app.options, 'reservationRefundFees', { type: 'percentage', value: 0 })
			}
			if (!state.allSettings.app.options.reservationAmount) {
				Vue.set(state.allSettings.app.options, 'reservationAmount', { value: 0, currency: 'USD' })
			}
			if (!state.allSettings.app.options.reservationNotification) {
				Vue.set(state.allSettings.app.options, 'reservationNotification', { users : [] })
			}
			if (!state.allSettings.app.options.reservationRelease) {
				Vue.set(state.allSettings.app.options, 'reservationRelease', { requiredMutualRelease: true })
			}
			if (!state.allSettings.app.options.reservationGraceDays) {
				Vue.set(state.allSettings.app.options, 'reservationGraceDays', { isActive: false, gracePeriod: 1 })
			}
			if (!state.allSettings.app.options.email) {
				Vue.set(state.allSettings.app.options, 'email', { sender: '', logo: '' })
			}
			if (!state.allSettings.app.options.agentCommissionType) {
				Vue.set(state.allSettings.app.options, 'agentCommissionType', 'units_and_addons')
			}
		},
		SET_PROP: (state, { where, what, del = false }) => {
			if (del) return deleteProp(state, where);

			if (what.status === null) what.status = "bh_lead";
			else if (typeof what.status === "object") what.status = what.status.id;

			if (what.tags && what.tags.length) {
				what.tags = what.tags.map((tag) => {
					if (typeof tag == "object") return tag.id;
					else return tag;
				});
			}
			setProp(state, where, what);
			state.newTime = Date.now();
		},
		UPDATE_CONDOPREMIUMS(state, data) {
			let newData = {};
			data.forEach((x) => {
				if (!x.hasOwnProperty("units")) x.units = [];
				x.value = x.value || 0;
				newData[x.id] = x;
			});
			state.condoPremiums = newData;
		},
		UPDATE_CONDOADDONS(state, data) {
			Vue.set(state, 'condoAddons', (data || { parking: [], lockers: [], bikeRacks: [] }))
		},
		UPDATE_CONDO_SETTINGS(state, data) {
			state.condoSettings = data;
		},
		SET_APPDATA(
			state,
			{ lots = [], unitGroups = [], units = [], siteplan = {}, users = [] }
		) {
			state.siteplan = siteplan;

			let siteplanObj = JSON.parse(JSON.stringify(siteplan));
			siteplanObj.lots = lots;
			siteplanObj.unitGroups = unitGroups;
			siteplanObj.units = units;

			state.siteplanMap = siteplanObj;
			state.users = users.filter((x) => x !== null && x.id);

			units.forEach((unit) => {
				if (unit.tags && unit.tags.length) {
					unit.tags = unit.tags.map((d) => {
						let id = d.id ? d.id : d;
						setProp(state.tags, [id], null);
						return id;
					});
				}
				if (unit.packages && unit.packages.length && unit.unitGroup) {
					Vue.set(state.units, unit.id, unit)
				}
			});

			// lots = lots.filter(x => x.units && x.units.length)
			lots.forEach((lot) => {
				if (lot.tags && lot.tags.length) {
					lot.tags.forEach((d) => {
						let id = d.id ? d.id : d;
						setProp(state.tags, [id], null);
					});
				}
				lot.units = lot.units.filter((id) => {
					return state.units[id];
				});
				if (!lot.status) {
					lot.status = 'available'
				}
				if (lot.units.length) {
					if (lot.attachedUnit && lot.attachedUnit.id)
						lot.attachedUnit = lot.attachedUnit.id;
					state.lots[lot.id] = lot;
				} else if (lot.type == "lot") {
					state.lots[lot.id] = lot;
				}
			});
			unitGroups.forEach((uG) => {
				state.unitGroups[uG.id] = uG;
			});

			let unitCategories = {};

			units.forEach((unit) => {
				if (!unit.salesStatus) {
					unit.salesStatus = 'available'
				}
				if (unit.salesStatus == "soldConditional") {
					unit.salesStatus = "conditional";
				}
				if (unit.buildingType == "condounits") {
					let pkg = (unit.packages && unit.packages[0]) || {};
					let {
						beds = false,
						hasDen,
						hasCondo,
						hasRoof,
						hasTerrace,
						hasPenthouse,
					} = pkg;
					if (hasCondo) {
						if (!unitCategories["Condotowns"])
							unitCategories["Condotowns"] = [];
						unitCategories["Condotowns"].push(unit);
					}
					if (hasRoof) {
						if (!unitCategories["Roof Top"]) unitCategories["Roof Top"] = [];
						unitCategories["Roof Top"].push(unit);
					}
					if (hasTerrace) {
						if (!unitCategories["Terrace Suites"])
							unitCategories["Terrace Suites"] = [];
						unitCategories["Terrace Suites"].push(unit);
					}
					if (hasPenthouse) {
						if (!unitCategories["Penthouse Suites"])
							unitCategories["Penthouse Suites"] = [];
						unitCategories["Penthouse Suites"].push(unit);
					}
					if (beds) {
						let bedCat = `${beds} Bedrooms${hasDen ? "+" : ""}`;
						if (!unitCategories[bedCat]) unitCategories[bedCat] = [];
						unitCategories[bedCat].push(unit);
					}
					state.catUnits = unitCategories;
				}
			});
		},
		GET_STARTED: (state) => {
			state.getStarted = true;
		},
		SET_OPTIONS: (state, { type, where, action = "", what, key }) => {
			if (!type) type = "app";
			if (!state.allSettings[type]) state.allSettings[type] = { options: {} }
			let settings = state.allSettings[type].options;
			what = JSON.parse(JSON.stringify(what));

			if (action.indexOf("array") === 0) {
				if (action === "arrayAdd") {
					if (!settings[where]) {
						settings[where] = [];
					}
					settings[where].push(what);
				} else if (action === "arrayEdit") {
					if (!key) key = "id";

					if (!settings.hasOwnProperty(where) || !what.hasOwnProperty(key))
						return;

					settings[where] = settings[where].map((x) =>
						x[key] === what[key] ? what : x
					);
				}
			} else if (!action) {
				if (!settings[where]) {
					settings[where] = [];
				}
				settings[where] = what;
			}
		}
	},
	actions: {
		GET_TIMELINES: async ({ commit }) => {
			commit("LOAD", true);
			$api
				.get("/timelines/:instance/ipm")
				.then((res) => {
					commit("SET_TIMELINES", res.data);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					commit("LOAD", false);
				});
		},
		SET_APPDATA: async ({ commit, rootState }, data) => {
			commit("SET_APPDATA", data);

			if (rootState.instance && rootState.instance.productType === "lowrise") {
				try {
					const result = await $api.get(`/tags/:instance?type=lots`);
					commit("DO_TAGS", result.data);

					let siteplanSettings = await $api.get("/settings/:instance/siteplan");

					if (
						siteplanSettings &&
						siteplanSettings.data &&
						siteplanSettings.data.options
					) {
						let { premiums = [], gradings = [] } = siteplanSettings.data.options;

						premiums.forEach((premium) => {
							commit("SET_PROP", {
								where: ["premiums", premium.id],
								what: premium,
							});
						});
						gradings.forEach((grading) => {
							commit("SET_PROP", {
								where: ["gradings", grading.id],
								what: grading,
							});
						});
					}
				} catch (error) {
					// Handle Error
				}
			} else {
				try {
					const result = await $api.get(`/tags/:instance?type=models`);
					commit("DO_TAGS", result.data);
					let condoSettings = await $api.get(`/settings/:instance/condounits`);

					if (condoSettings && condoSettings.data) {
						commit("UPDATE_CONDO_SETTINGS", condoSettings.data);

						if (condoSettings.data.options) {
							commit(
								"UPDATE_CONDOPREMIUMS",
								condoSettings.data.options.premiums || []
							);
							commit(
								"UPDATE_CONDOADDONS",
								condoSettings.data.options.addons || []
							);
						}
					}
				} catch (error) {
					// Handle Error
				}
			}
		},
		CLOSE_FORM_DRAWER: ({ commit }, { type, data }) => {
			if (type !== "click")
				commit("SET_PROP", {
					where: ["allForms", data.id],
					what: data,
					del: type === "delete",
				});
			commit("CLOSE_DRAWERS");
		},
		async FETCH_UPDATED_DATA({ commit, dispatch }) {
			commit("LOAD", true);
			try {
				const { data } = await $api.get(`/hives/:instance/inventory`)
				dispatch("SET_APPDATA", data.data);
				commit("SET_SETTINGS", data.settings);
			} catch (error) {
				console.error(err);
			}
			commit("LOAD", false);
		},
		async FETCH_ADD_ONS({ commit }) {
			commit("LOAD", true);
			try {
				const { data } = await $api.get(`units/:instance/addons`)
				commit("UPDATE_CONDOADDONS", data);
			} catch (error) {
				console.error(err);
			}
			commit("LOAD", false);
		}
	},
	getters: {
		elevationName: (state) => {
			let names = [];
			Object.values(state.units).forEach((unit) => {
				if (unit.name && !names.includes(unit.name.replace(/\s/g, "")))
					names.push(unit.name.replace(/\s/g, ""));
			});
			names.sort((a, b) => {
				if (a.toLowerCase() < b.toLowerCase()) return -1;
				if (a.toLowerCase() > b.toLowerCase()) return 1;
				return 0;
			});
			return names;
		},
		elevationPrices: (state) => {
			let map = {};
			let max = 0;

			Object.values(state.units).forEach((unit) => {
				if (unit.packages && unit.packages.length) {
					unit.packages.forEach((pack) => {
						if (!map[pack.price]) {
							map[pack.price] = [unit.id];
						} else {
							map[pack.price].push(unit.id);
						}
					});
				}
			});

			max = Math.max(max, ...Object.keys(map));

			return { max: max, priceMap: map };
		},
		unitsByPrice: (state) => {
			let map = {};
			let max = 0;

			Object.values(state.units).forEach((unit) => {
				let price = 0;
				if (unit.packages && unit.packages.length && unit.packages[0].price)
					price = unit.packages[0].price;
				if (!map[price]) {
					map[price] = [unit.id];
				} else {
					map[price].push(unit.id);
				}
			});

			max = Math.max(...Object.keys(map));
			return { max: max, priceMap: map };
		},
		unitsBySqft: (state) => {
			let map = {};
			let max = 0;

			Object.values(state.units).forEach((unit) => {
				let sqft = 0;
				if (unit.packages && unit.packages.length && unit.packages[0].sqft)
					sqft = unit.packages[0].sqft;
				if (!map[sqft]) {
					map[sqft] = [unit.id];
				} else {
					map[sqft].push(unit.id);
				}
			});

			max = Math.max(...Object.keys(map));
			return { max: max, sqftMap: map };
		},
		lotsByPremiums: (state) => {
			let map = {};
			let max = 0;

			Object.values(state.lots).forEach((lot) => {
				let price = 0;
				if (lot.other && lot.other.premiums) {
					Object.values(lot.other.premiums).forEach((premium) => {
						if (premium.price && premium.active) {
							price = price + premium.price;
						}
					});
				}
				if (!map[price]) {
					map[price] = [lot.id];
				} else {
					map[price].push(lot.id);
				}
			});

			max = Math.max(...Object.keys(map));
			return { max: max, priceMap: map };
		},
		getCount: (state, getters, rootState) => {
			let refresh = state.newTime;
			console.log("Get count from store rerun...");
			let map = {};
			let stats = [
				"available",
				"sold",
				"hold",
				"conditional",
				"inventory",
				"notreleased",
				"allocated",
				"approved_for_reservation",
				"reserved",
			];
			if (rootState.instance && rootState.instance.productType === "lowrise") {
				let lots = Object.values(state.lots);
				map["total"] = lots.length;
				stats.forEach((stat) => {
					if (stat == "conditional") {
						map[stat] = lots.filter(
							(x) => x.status == stat || x.status == "soldConditional"
						).length;
					} else {
						map[stat] = lots.filter((x) => x.status == stat).length;
					}
				});
				return map;
			} else {
				let units = Object.values(state.units);
				map["total"] = units.length;
				stats.forEach((stat) => {
					if (stat == "available") {
						map[stat] = units.filter(
							(x) => x.salesStatus == stat || x.salesStatus == "inventory"
						).length;
					} else {
						map[stat] = units.filter((x) => x.salesStatus == stat).length;
					}
				});
				return map;
			}
		},
		sidebarItems: (state, getters, rootState, rootGetters) => {
			let items = [
				{
					label: rootState.instance && rootState.instance.productType === "highrise" ? "Unit Inventory" : "Inventory Management",
					value: "inventory",
					iconUrl: require("@/assets/house-solid.svg"),
					options: [],
					children: [],
					visibleCondition: true
				},
				{
					label: "Add Ons Inventory",
					value: "unitAddOns",
					iconUrl: require("@/assets/square-plus-solid.svg"),
					options: [],
					children: [],
					visibleCondition: rootState.instance && rootState.instance.productType === "highrise"
				},
				{
					label: "Price Management",
					value: "priceManagement",
					iconUrl: require("@/assets/money-bill-solid.svg"),
					options: [],
					children: [],
					visibleCondition: Vue.prototype.$userType !== 'agent'
				},
				{
					label: "Price History",
					value: "priceLog",
					iconUrl: require("@/assets/history-solid.svg"),
					options: [],
					children: [],
					visibleCondition: Vue.prototype.$userType !== 'agent'
				},
				{
					label: "Commission & Allocation Management",
					value: "allocation",
					iconUrl: require("@/assets/allocation-solid.svg"),
					options: [],
					children: [],
					visibleCondition: rootGetters.isPremiumPackage && Vue.prototype.$userType !== 'agent'
				},
				{
					label: "Commission & Allocation History",
					value: "commissionAllocationHistory",
					iconUrl: require("@/assets/history-solid.svg"),
					options: [],
					children: [],
					visibleCondition: rootGetters.isPremiumPackage && Vue.prototype.$userType !== 'agent'
				},
				{
					divider: true,
					visibleCondition: true
				},
				{
					label: "Settings",
					value: "settings",
					iconUrl: require("@/assets/cog-solid.svg"),
					options: [],
					children: [],
					visibleCondition: true
				},
			];

			return items.filter(i => i.visibleCondition);
		},
		elevOptions: (state, getters) => {
			let minPrice = 0,
				maxPrice = 0,
				prices = [],
				minBeds = 0,
				maxBeds = 0,
				beds = [],
				minBaths = 0,
				maxBaths = 0,
				baths = [];

			Object.values(getters.units).forEach((unit) => {
				unit.packages.forEach((pkg) => {
					if (pkg.price) {
						prices.push(pkg.price);
					}
					if (pkg.beds) {
						if (!beds.includes(pkg.beds)) beds.push(pkg.beds);
					}
					if (pkg.baths) {
						if (!baths.includes(pkg.baths)) baths.push(pkg.baths);
					}
				});
			});

			if (prices.length) {
				let min = Math.min(...prices);
				let max = Math.max(...prices);
				minPrice = min;
				maxPrice = max;
			}

			beds = beds.sort();
			if (beds.length) {
				let min = Math.min(...beds);
				let max = Math.max(...beds);
				minBeds = min;
				maxBeds = max;
			}

			baths = baths.sort();
			if (baths.length) {
				let min = Math.min(...baths);
				let max = Math.max(...baths);
				minBaths = min;
				maxBaths = max;
			}

			return {
				minPrice,
				maxPrice,
				prices,
				minBeds,
				maxBeds,
				beds,
				minBaths,
				maxBaths,
				baths,
			};
		},
		addOnsOptions: (state) => {
			let parkingPrices = [];
			let parkingTypes = [];
			let minParkingPrice = 0;
			let maxParkingPrice = 0;

			let lockersPrices = [];
			let lockersTypes = [];
			let minLockersPrice = 0;
			let maxLockersPrice = 0;

			let bikeRacksPrices = [];
			let minBikeRacksPrice = 0;
			let maxBikeRacksPrice = 0;

			Object.keys(state.condoAddons).forEach((addon) => {
				state.condoAddons[addon].forEach(a => {
					if (a.price && a.price >= 0) {
						if (addon === 'parking') parkingPrices.push(a.price);
						if (addon === 'lockers') lockersPrices.push(a.price);
						if (addon === 'bikeRacks') bikeRacksPrices.push(a.price);
					}

					if (a.type) {
						if (addon === 'parking' && !parkingTypes.includes(a.type)) parkingTypes.push(a.type);
						if (addon === 'lockers' && !lockersTypes.includes(a.type)) lockersTypes.push(a.type);
					}
				})
			})

			if (parkingPrices.length) {
				minParkingPrice = Math.min(...parkingPrices);
				maxParkingPrice = Math.max(...parkingPrices);
			}

			if (lockersPrices.length) {
				minLockersPrice = Math.min(...lockersPrices);
				maxLockersPrice = Math.max(...lockersPrices);
			}

			if (bikeRacksPrices.length) {
				minBikeRacksPrice = Math.min(...bikeRacksPrices);
				maxBikeRacksPrice = Math.max(...bikeRacksPrices);
			}

			return {
				parkingPrices,
				parkingTypes,
				minParkingPrice,
				maxParkingPrice,
				lockersPrices,
				lockersTypes,
				minLockersPrice,
				maxLockersPrice,
				bikeRacksPrices,
				minBikeRacksPrice,
				maxBikeRacksPrice,
			}
		},
		highRiseOptions: (state, getters) => {
			let maxPrice = 0;
			let minPrice = 0;
			let prices = [];
			let sqft = [];
			let minSqft = 0;
			let maxSqft = 0;
			let baths = [];
			let bedOptions = [];
			let exteriors = [];
			let map = {};

			Object.values(getters.units).forEach((unit) => {
				let pkg = unit.packages[0];

				if (pkg.price && pkg.price >= 0) {
					prices.push(unit.packages[0].price);
				}
				if (pkg.sqft) {
					sqft.push(unit.packages[0].sqft);
				}
				if (!baths.includes(pkg.baths)) baths.push(unit.packages[0].baths);

				let {
					beds = false,
					hasDen,
					hasCondo,
					hasRoof,
					hasTerrace,
					hasPenthouse,
				} = pkg;

				if (hasCondo) {
					if (!bedOptions.includes("Condotowns")) bedOptions.push("Condotowns");
					if (!map["Condotowns"]) {
						map["Condotowns"] = [unit.id];
					} else {
						map["Condotowns"].push(unit.id);
					}
				}
				if (hasRoof) {
					if (!bedOptions.includes("Roof Top")) bedOptions.push("Roof Top");
					if (!map["Roof Top"]) {
						map["Roof Top"] = [unit.id];
					} else {
						map["Roof Top"].push(unit.id);
					}
				}
				if (hasTerrace) {
					if (!bedOptions.includes("Terrace Suites"))
						bedOptions.push("Terrace Suites");
					if (!exteriors.includes("terrace")) exteriors.push("terrace");
					if (!map["Terrace Suites"]) {
						map["Terrace Suites"] = [unit.id];
					} else {
						map["Terrace Suites"].push(unit.id);
					}
				}
				if (hasPenthouse) {
					if (!bedOptions.includes("Penthouse Suites"))
						bedOptions.push("Penthouse Suites");
					if (!exteriors.includes("penthouse")) exteriors.push("penthouse");
					if (!map["Penthouse Suites"]) {
						map["Penthouse Suites"] = [unit.id];
					} else {
						map["Penthouse Suites"].push(unit.id);
					}
				}
				if (beds) {
					let bedCat = {
						label: `${beds}${hasDen ? "+1" : ""} Bedrooms`,
						value: `${beds}${hasDen ? "+1" : ""}`,
					};
					if (hasDen && !exteriors.includes("den")) exteriors.push("den");
					if (!bedOptions.includes(bedCat.value)) bedOptions.push(bedCat.value);
					if (!map[bedCat.value]) {
						map[bedCat.value] = [unit.id];
					} else {
						map[bedCat.value].push(unit.id);
					}
				}
			});

			bedOptions = bedOptions.sort();
			baths = baths.sort();

			if (prices.length) {
				let min = Math.min(...prices);
				let max = Math.max(...prices);
				minPrice = min;
				maxPrice = max;
			}
			if (sqft.length) {
				let min = Math.min(...sqft);
				let max = Math.max(...sqft);
				minSqft = min;
				maxSqft = max;
			}

			return {
				minPrice,
				maxPrice,
				bedOptions,
				baths,
				prices,
				minSqft,
				maxSqft,
				sqft,
				exteriors,
				map,
			};
		},
		lowRiseOptions: (state) => {
			let minPrice = 0,
				maxPrice = 0,
				prices = [];

			Object.values(state.lots).forEach((lot) => {
				let lotPremiums = 0;

				let { other = {} } = lot;
				let { premiums = {}, gradings = {} } = other;

				Object.values(premiums).forEach((premium) => {
					if (premium.active && premium.price) {
						lotPremiums += premium.price;
					}
				});
				Object.values(gradings).forEach((grading) => {
					if (grading.active && grading.price) {
						lotPremiums += grading.price;
					}
				});
				if (lotPremiums) {
					prices.push(lotPremiums);
				}
			});

			if (prices.length) {
				let min = Math.min(...prices);
				let max = Math.max(...prices);
				minPrice = min;
				maxPrice = max;
			}

			return {
				minPrice,
				maxPrice,
				prices,
			};
		},
		floorUnits: (state, getters) => {
			return getters.units.filter((x) => {
				return x.unitGroup.id === state.selectedFloor;
			});
		},
		units: (state, getters, rootState) => {
			return Object.values(state.units).filter((unit) => {
				if (rootState.instance && rootState.instance.productType === "highrise") {
					if (unit.buildingType == "condounits" && unit.unitGroup) {
						return true;
					}
				} else if (rootState.instance && rootState.instance.productType === "lowrise") {
					if (unit.buildingType == "models" && unit.unitGroup) {
						return true;
					}
				}
			});
		},
		floors: (state) => {
			let obj = {};
			Object.values(state.unitGroups).forEach((uG) => {
				if (uG.buildingType == "condounits") {
					obj[uG.id] = uG;
				}
			});
			return obj;
		},
		isLowriseFiltered: (state) => {
			let { modelType, tags, status, assignee, street, elevation, unitGroup } = state.lowriseFilter;
			let isFiltered = false;
			if (
				modelType && modelType[0] !== "any" ||
				tags && tags[0] !== "any" ||
				status && status[0] !== "any" ||
				assignee && assignee[0] != "any" ||
				street !== "any" ||
				elevation !== "any" ||
				unitGroup !== "any"
			) {
				isFiltered = true;
			}
			return isFiltered;
		},
		isHighriseFiltered: (state) => {
			let filter = state.highriseFilter;
			if (
				filter.price.length ||
				filter.beds[0] != "any" ||
				filter.baths != "any" ||
				filter.status[0] != "any" ||
				filter.assignee[0] != "any" ||
				filter.exposure[0] != "any"
			) {
				return true;
			}
			return false;
		},
		filteredLots: (state) => {
			const orderAlpha = (array) => {
				array = array.sort((a, b) => {
					if (parseInt(a.name) < parseInt(b.name)) return -1;
					if (parseInt(a.name) > parseInt(b.name)) return 1;
					else return 0;
				});
				return array;
			};

			let data = state.lowriseFilter;

			if (
				data.modelType && data.modelType[0] == "any" &&
				data.status && data.status[0] == "any" &&
				data.street == "any" &&
				data.elevation == "any" &&
				data.unitGroup == "any"
			) {
				return state.lots;
			}

			let obj = {};

			if (data.modelType && data.modelType[0] != "any") {
				Object.values(state.lots).forEach((lot) => {
					lot.units.forEach((unit) => {
						let id = "";
						if (typeof unit == "string") id = unit;
						else if (typeof unit == "object") id = unit.id;
						if (
							state.units[id].unitGroup &&
							data.modelType.includes(state.units[id].unitGroup.type)
						) {
							obj[lot.id] = lot;
						}
					});
				});
			}
			if (data.status && data.status[0] != "any") {
				let statObj = {};
				let temp = {};
				if (data.modelType[0] != "any") temp = obj;
				else temp = state.lots;
				Object.values(temp).forEach((lot) => {
					if (data.status.includes(lot.status)) {
						statObj[lot.id] = lot;
					}
				});
				obj = statObj;
			}
			if (data.street != "any") {
				let streetObj = {};
				let temp = {};
				if (data.modelType[0] != "any" || data.status[0] != "any") temp = obj;
				else temp = state.lots;
				Object.values(temp).forEach((lot) => {
					if (lot.street == data.street) {
						streetObj[lot.id] = lot;
					}
				});
				obj = streetObj;
			}
			if (data.elevation != "any") {
				let elevationObj = {};
				let temp = {};
				if (
					data.modelType[0] != "any" ||
					data.status[0] != "any" ||
					data.street != "any"
				)
					temp = obj;
				else temp = state.lots;
				Object.values(temp).forEach((lot) => {
					if (lot.units.includes(data.elevation)) {
						elevationObj[lot.id] = lot;
					}
				});
				obj = elevationObj;
			}
			return orderAlpha(Object.values(obj));
		},
	},
};
