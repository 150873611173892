<template>
	<div id="app">
		<BhLoading :show="loading" />
		<MediaSelector />
		<LibrarySelector />
		<localization></localization>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Localization from 'bh-mod/localization'
import MediaSelector from 'bh-mod/components/file/MediaSelector'
import LibrarySelector from 'bh-mod/components/file/LibrarySelector'
import BhLoading from 'bh-mod/components/common/Loading'

export default {
	name: 'app',
	components: { Localization, MediaSelector, BhLoading, LibrarySelector },
	computed: {
		...mapGetters(['user']),
		loading() {
			return this.$store.state.loading
		},
	},
	watch: {
		'$route'(to, from) {
			const query = Object.assign({}, to.query)
			this.$store.commit('SETUP_URL_SETTINGS', query)
		},
	},
	methods: {

	},

}
</script>
<style>
#app {
	min-height: 100vh;
}

.display-card {
	border-radius: 3px;
	border-color: rgba(63, 63, 63, .15);
	box-shadow: 0 1px 3px rgb(63 63 68 / 15%);
	border: none;
	background-color: #FFF;
	overflow-x: auto;
}

.grid-display-4 {
	grid-template-columns: repeat(4, minmax(0, 1fr));
	display: grid;
}

.hover-dotted {
	border-bottom: 1px dotted transparent;
}

.hover-dotted:hover {
	display: inline-block;
	border-color: #ccc;
	cursor: pointer;
	color: var(--primary);
}

/* table left-right scroll arrows css */
.scroll-button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-color: rgb(220 220 220 / 50%);
	border: none;
	color: #000;
	font-size: large;
	padding: 10px;
	cursor: pointer;
	height: 100%;
	display: flex;
	align-items: center;
}

.scroll-left {
	left: 0;
}

.scroll-right {
	right: 0;
}

.hide {
	display: none;
}
</style>
<style lang="scss">
.custom-scrollbar::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background: #F2F2F2;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background: var(--grey-purple);
	;
	border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background: var(--orange);
}

.display-card::-webkit-scrollbar-thumb {
	background: #000 !important;
}

// card div view left-right scroll arrows css
#left, #right {
  width: 25px;
  height: 100%;
  float: left;
  text-align: center;
  font-size: large;
  cursor: pointer;
}

.price-content {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  margin: 0 10px;
  -webkit-overflow-scrolling: touch;
}

.price-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
