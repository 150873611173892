<template>
    <div>
        <div
            :class="$style.subbar"
            v-if="instance"
            style="background-color: #f7f5f9"
        >
            <div class="dF aC mb-0 jSB">
                <div
                    :class="$style.breadcrumbs"
                    style="align-items: center; display: block !important"
                >
                    <a-breadcrumb>
                        <a-breadcrumb-item>{{
                            instance.name
                        }}</a-breadcrumb-item>
                        <a-breadcrumb-item>{{
                            selectedAppInitial
                        }}</a-breadcrumb-item>
                        <a-breadcrumb-item style="color: var(--orange)">{{
                            sidebarItems[crumbs]
                        }}</a-breadcrumb-item>
                        <!-- <a-breadcrumb-item :style="crumbI == crumbs.length-1 ? 'color:#FF9027':''" v-for="(crumb,crumbI) in crumbs" :value="crumbI" :key="crumbI">{{crumb}}</a-breadcrumb-item> -->
                    </a-breadcrumb>
                </div>

                <a-auto-complete
                    v-if="
                        $route.path == '/' &&
                        instance.productType == 'lowrise' &&
						currentView == 'grid'
                    "
                    style="width: 300px"
                    placeholder="Search for a lot..."
                    :dropdown-match-select-width="false"
                    :dropdown-style="{ width: '300px' }"
                    option-label-prop="value"
                    @search="handleSearch"
                    size="large"
					allowClear
                >
                    <template slot="dataSource">
                        <!-- <a-select-opt-group> -->
                        <a-select-option
                            @click="optionClicked(lot.id)"
                            v-for="(lot, lotI) in searchResult"
                            :key="lotI + lot.id"
                            :value="`${lot.type} ${lot.name}`"
                        >
                            <div class="dF aC">
                                <div class="f1">
                                    <div>
                                        <strong
                                            >{{
                                                lot.type
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                lot.type.slice(1)
                                            }}
                                            {{ lot.name }}</strong
                                        >
                                    </div>
                                </div>
                            </div>
                        </a-select-option>
                        <!-- </a-select-opt-group> -->
                    </template>
                    <a-input>
                        <a-icon
                            style="stroke: #000; stroke-width: 34"
                            slot="prefix"
                            type="search"
                        />
                    </a-input>
                </a-auto-complete>

				<a-auto-complete
                    v-if="
                        $route.path == '/priceManagement' &&
                        instance.productType == 'lowrise' &&
						priceTab == 'lot'
                    "
                    style="width: 300px"
                    placeholder="Search for a lot..."
                    :dropdown-match-select-width="false"
                    :dropdown-style="{ width: '300px' }"
                    option-label-prop="value"
                    @search="handleSearchLot"
                    size="large"
					allowClear
                >
                    <template slot="dataSource">
                        <!-- <a-select-opt-group> -->
                        <a-select-option
                            @click="optionClickedLot(lot.id)"
                            v-for="(lot, lotI) in searchResultLot"
                            :key="lotI + lot.id"
                            :value="`${lot.type} ${lot.name}`"
                        >
                            <div class="dF aC">
                                <div class="f1">
                                    <div>
                                        <strong
                                            >{{
                                                lot.type
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                lot.type.slice(1)
                                            }}
                                            {{ lot.name }}</strong
                                        >
                                    </div>
                                </div>
                            </div>
                        </a-select-option>
                        <!-- </a-select-opt-group> -->
                    </template>
                    <a-input>
                        <a-icon
                            style="stroke: #000; stroke-width: 34"
                            slot="prefix"
                            type="search"
                        />
                    </a-input>
                </a-auto-complete>

				<a-auto-complete
                    v-if="
                        $route.path == '/priceManagement' &&
                        instance.productType == 'lowrise' &&
						priceTab == 'elevation'
                    "
                    style="width: 300px"
                    placeholder="Search for an elevation..."
                    :dropdown-match-select-width="false"
                    :dropdown-style="{ width: '300px' }"
                    option-label-prop="value"
                    @search="handleSearchElevation"
                    size="large"
					allowClear
                >
                    <template slot="dataSource">
                        <!-- <a-select-opt-group> -->
                        <a-select-option
                            @click="optionClickedElevation(ele.id)"
                            v-for="(ele, eleI) in searchResultElevation"
                            :key="eleI + ele.id"
                            :value="`${ele.unitGroup.name} ${ele.name}`"
                        >
                            <div class="dF aC">
                                <div class="f1">
                                    <div>
                                        <strong
                                            >{{
                                                ele.unitGroup.name
                                            }}
                                            {{ ele.name }}</strong
                                        >
                                    </div>
                                </div>
                            </div>
                        </a-select-option>
                        <!-- </a-select-opt-group> -->
                    </template>
                    <a-input>
                        <a-icon
                            style="stroke: #000; stroke-width: 34"
                            slot="prefix"
                            type="search"
                        />
                    </a-input>
                </a-auto-complete>

				<a-auto-complete
                    v-if="
                        $route.path == '/' &&
                        instance.productType === 'highrise'
                    "
                    style="width: 300px"
                    placeholder="Search for a unit..."
                    :dropdown-match-select-width="false"
                    :dropdown-style="{ width: '300px' }"
                    option-label-prop="value"
                    @search="handleSearchUnit"
                    size="large"
					allowClear
                >
                    <template slot="dataSource">
                        <a-select-option
                            @click="optionClickedUnit(unit.id)"
                            v-for="(unit, unitI) in searchResultUnit"
                            :key="unitI + unit.id"
                            :value="`Unit ${unit.unitNumber} ${unit.name}`"
                        >
                            <div class="dF aC">
                                <div class="f1">
                                    <div>
                                        <strong
                                            >Unit {{
                                                unit.unitNumber
                                            }}
                                            {{ unit.name }}</strong
                                        >
                                    </div>
                                </div>
                            </div>
                        </a-select-option>
                    </template>
                    <a-input>
                        <a-icon
                            style="stroke: #000; stroke-width: 34"
                            slot="prefix"
                            type="search"
                        />
                    </a-input>
                </a-auto-complete>
            </div>
        </div>
    </div>
</template>
<script>
import { getAppIcon } from "bh-mod";
import SubBarGlobal from "bh-mod/components/layout/SubBarGlobal";
import PageButtons from "bh-mod/components/layout/SubBarGlobal/PageButtons";
export default {
    components: {
        SubBarGlobal,
        PageButtons,
    },
    data() {
        return {
            searchResult: [],
			searchResultUnit: [],
			searchResultLot: [],
			searchResultElevation: [],
        };
    },
    watch: {
        currentTab: {
            handler(val) {
                if (val != "") {
                    if (
                        this.crumbs[0] == "Price Management" &&
                        this.crumbs.length == 2 &&
                        this.crumbs[1] != val
                    ) {
                        let crumbs = JSON.parse(JSON.stringify(this.crumbs));
                        crumbs.splice(1, 1);
                        crumbs.push(val);
                        this.$store.commit("UPDATE_CRUMBS", crumbs);
                    }
                }
            },
        },
        crumbs: {
            handler(val) {
                if (
                    val.length == 1 &&
                    val[0] == "Price Management" &&
                    this.currentTab == "" &&
                    this.$store.state.instance.productType == "lowrise"
                ) {
                    let crumbs = JSON.parse(JSON.stringify(this.crumbs));
                    crumbs.push("Lot Price");
                    this.$store.commit("UPDATE_CRUMBS", crumbs);
                } else if (
                    val.length == 1 &&
                    val[0] == "Price Management" &&
                    this.currentTab != ""
                ) {
                    let crumbs = JSON.parse(JSON.stringify(this.crumbs));
                    crumbs.push(this.currentTab);
                    this.$store.commit("UPDATE_CRUMBS", crumbs);
                }
            },
        },
    },
    computed: {
		currentView(){
			return this.$store.state.inventory.currentView
		},
		priceTab(){
			return this.$store.state.inventory.priceManagement;
		},
        selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "IPM"
            );
        },
        lots() {
            let state = this.$store.state.inventory;
            const orderAlpha = (array) => {
                array = array.sort((a, b) => {
                    if (parseInt(a.name) < parseInt(b.name)) return -1;
                    if (parseInt(a.name) > parseInt(b.name)) return 1;
                    else return 0;
                });
                return array;
            };
            return orderAlpha(Object.values(state.lots));
        },
		units() {
            let state = this.$store.state.inventory;
            const orderAlpha = (array) => {
                array = array.sort((a, b) => {
                    if (parseInt(a.unitNumber) < parseInt(b.unitNumber))
                        return -1;
                    if (parseInt(a.unitNumber) > parseInt(b.unitNumber))
                        return 1;
                    else return 0;
                });
                return array;
            };
            return orderAlpha(Object.values(state.units)).filter(unit => unit.buildingType == "condounits");
        },
		elevations() {
			let state = this.$store.state.inventory;
			const orderAlpha = (array) => {
				array = array.sort((a,b) => {
					if (parseInt(a.name) < parseInt(b.name)) return -1
					if (parseInt(a.name) > parseInt(b.name)) return 1
					else return 0
				})
				return array
			}
			return orderAlpha(Object.values(state.units));
		},
        sidebarItems() {
            let map = {};
            this.$store.getters.sidebarItems.forEach((item) => {
                map[item.value] = item.label;
            });
            return map;
        },
        instance() {
            return this.$store.state.instance;
        },
        crumbs() {
            return this.$store.state.inventory.crumbs;
        },
        currentTab() {
            return this.$store.state.inventory.currentTab;
        },
    },
    methods: {
        getAppIcon,
		getPremiums(id,withPrice) {
			let prems = []
			let lot = this.$store.state.inventory.lots[id]
			if (lot.other.premiums && Object.keys(lot.other.premiums).length) {
				Object.values(lot.other.premiums).forEach(prem => {
					if (prem.active){
						let label = prem.name
						if (withPrice) {
							label = `${label} $${prem.price.toLocaleString('US')}`
						}
						prems.push(label)
					}
				})
			}


			return prems
		},
        optionClicked(id) {
            let filter = {
                assignee: ["any"],
				price: [0, 0],
				frontage: [0],
				unitGroup: "any",
				elevation: "any",
				modelType: ["any"],
				tags: ["any"],
				street: "any",
				premiums: ["any"],
            };
            this.$store.commit("UPDATE_LOWRISE_FILTER", filter);

            setTimeout(() => {
                let lotCard = document.querySelector(`[data-lot-id="${id}"]`);
                if (lotCard != null) {
                    lotCard.scrollIntoView({ behavior: "smooth" });
                    lotCard.classList.add("highlight-lotCard");
                    setTimeout(() => {
                        lotCard.classList.remove("highlight-lotCard");
                    }, 2000);
                }
            }, 100);
        },
		optionClickedUnit(id) {
            let filter = {
                assignee: ["any"],
				type: ["any"],
				price: [0, 0],
				sqft: [0, 0],
				beds: ["any"],
				baths: "any",
				floor: "any",
				exposure: ["any"],
				exterior: ["any"],
				tags: ["any"],
            };
            this.$store.commit("UPDATE_HIGHRISE_FILTER", filter);

            setTimeout(() => {
                let lotCard = document.querySelector(`[data-unit-id="${id}"]`);
                if (lotCard != null) {
                    lotCard.scrollIntoView({ behavior: "smooth" });
                    lotCard.classList.add("highlight-lotCard");
                    setTimeout(() => {
                        lotCard.classList.remove("highlight-lotCard");
                    }, 2000);
                }
            }, 100);
        },
		optionClickedLot(id) {
			let filter = {
                price: [0, 0],
				frontage: [0],
				modelType: ["any"],
				tags: ["any"],
				street: "any",
				premiums: ["any"],
				status: ["any"],
            };
            this.$store.commit("UPDATE_LOT_FILTER", filter);

            setTimeout(() => {
                let lotCard = document.querySelector(`[data-lot-id="${id}"]`);
                if (lotCard != null) {
                    lotCard.scrollIntoView({ behavior: "smooth" });
                    lotCard.classList.add("highlight-lotCard");
                    setTimeout(() => {
                        lotCard.classList.remove("highlight-lotCard");
                    }, 2000);
                }
            }, 100);
        },
		optionClickedElevation(id) {
			let filter = {
                price: [0, 0],
				frontage: [0],
				modelType: ["any"],
				beds: ["any"],
				baths: ["any"],
				unitGroup: "any",
				elevation: "any",
				tags: ["any"]
            };
            this.$store.commit("UPDATE_ELEVATION_FILTER", filter);

            setTimeout(() => {
                let lotCard = document.querySelector(`[data-elevation-id="${id}"]`);
                if (lotCard != null) {
                    lotCard.scrollIntoView({ behavior: "smooth" });
                    lotCard.classList.add("highlight-lotCard");
                    setTimeout(() => {
                        lotCard.classList.remove("highlight-lotCard");
                    }, 2000);
                }
            }, 100);
        },
        handleSearch(input) {
            if (input && input.length >= 1) {
                this.searchResult = this.lots.filter(({ name, type }) => {
                    name = name && name.toLowerCase();
                    type = type && type.toLowerCase();
                    let fullName = type + " " + name;
                    input = input.toLowerCase();
                    return (
                        name.includes(input) ||
                        type.includes(input) ||
                        fullName.includes(input)
                    );
                });
            } else {
                return (this.searchResult = []);
            }
        },
		handleSearchUnit(input) {
            if (input && input.length >= 1) {
                this.searchResultUnit = this.units.filter(({ name, unitNumber, unitCode }) => {
                    name = name.toLowerCase();
                    unitNumber = unitNumber.toLowerCase();
                    unitCode = unitCode.toLowerCase();
                    input = input.toLowerCase();
                    return (
						(name.includes(input) ||
						unitNumber.includes(input) ||
                        unitCode.includes(input) )
                    );
                });
            } else {
                return (this.searchResultUnit = []);
            }
        },
		handleSearchLot(input) {
            if (input && input.length >= 1) {
                this.searchResultLot = this.lots.filter(({ name, type, id, status }) => {
                    name = name.toLowerCase();
                    type = type.toLowerCase();
                    let fullName = type + " " + name;
                    input = input.toLowerCase();
                    return (
						(name.includes(input) ||
                        type.includes(input) ||
                        fullName.includes(input))
                    );
                });
            } else {
                return (this.searchResultLot = []);
            }
        },
		handleSearchElevation(input) {
            if (input && input.length >= 1) {
                this.searchResultElevation = this.elevations.filter(({ unitGroup, name }) => {
                    name = name.toLowerCase();
                    let unitGroupName = unitGroup.name.toLowerCase();
                    let fullName = unitGroupName + " " + name;
                    input = input.toLowerCase();
                    return (
                        name.includes(input) ||
                        unitGroupName.includes(input) ||
                        fullName.includes(input)
                    );
                });
            } else {
                return (this.searchResultElevation = []);
            }
        },
    },
};
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss">
.ant-form-horizontal.search-horizontal {
    label {
    }
    .ant-form-item-control-wrapper {
        flex: 1;
    }
    .ant-form-item {
        display: flex;
    }
    .ant-form-item-label {
        min-width: 100px;
    }
}
.highlight-lotCard {
    border: 1px solid var(--orange) !important;
    animation: highlight-lotCard 1s ease-in-out;
}
</style>
